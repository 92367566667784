import type { GetCompanyResponse } from '~/feature/companies/types'
import { getApiClient } from '~/lib/apiClientSingleton'

const getCompany = async (companyCode: string): Promise<GetCompanyResponse> => {
  const apiClient = getApiClient()
  return await apiClient.fetchFromAPI<GetCompanyResponse>({
    endpoint: `/companies/${companyCode}`,
    options: apiClient.getRequestOptions(),
  })
}

export default getCompany
